<template>
  <div class="page">
    <import-template
      label="手环导入模板"
      style="box-sizing: border-box; height: 100%"
      :uploadImgUrl="uploadImgUrl"
      :downloadUrl="downloadUrl"
      :showCloseBtn="true"
    ></import-template>
  </div>
</template>

<script>
import importTemplate from "@/components/bussiness/import-template/index";
import { importWatchDeviceURL } from "./api";

export default {
  name: "elderCareBraceletImport",
  data() {
    return {
      downloadUrl: "/static/excel/手环导入模板.xlsx",
      uploadImgUrl: importWatchDeviceURL,
    };
  },
  components: {
    importTemplate,
  },
  mounted() {
    this.$setBreadList("手环导入");
  },
  methods: {
    // submitSuccess() {
    //   return true;
    // },
    // submitBefore() {
    //   return true;
    // },
    // update(data) {
    //   Object.keys(this.form).forEach((key) => {
    //     this.form[key] = data[key];
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
